import Palette from './Palette';

import widget_recent_search_mob from '@/shared/images/Images/widget_recent_search_mob.png';
import widget_recent_search_desk from '@/shared/images/Images/widget_recent_search_desk.png';
import widget_recent_search_ipad from '@/shared/images/Images/widget_recent_search_ipad.png';
import widget_recent_search_ipad_dark from '@/shared/images/Images/widget_recent_search_ipad_dark.png';
import widget_recent_search_mob_dark from '@/shared/images/Images/widget_recent_search_mob_dark.png';
import widget_recent_search_desk_dark from '@/shared/images/Images/widget_recent_search_desk_dark.png';

import emptyattraction_ipad from '@/shared/images/Images/emptyattraction_ipad.png';
import emptyattraction_ipad_dark from '@/shared/images/Images/emptyattraction_ipad_dark.png';
import emptyattraction_mobile from '@/shared/images/Images/emptyattraction_mobile.png';
import emptyattraction_mobile_dark from '@/shared/images/Images/emptyattraction_mobile_dark.png';
import emptyattraction_desktop_dark from '@/shared/images/Images/emptyattraction_desktop_dark.png';
import emptyattraction_desktop from '@/shared/images/Images/emptyattraction_desktop.png';

import HomePageLight from '@/shared/images/HeroImages/HomePageLight.jpg';
import HomePageDark from '@/shared/images/HeroImages/HomePageDark.jpg';

const { gray, white, green, blue, dark, ash, black, error, sand, sidemenu, state, red, paypal } =
    Palette;

export const cssVariablesValues: { [key: string]: string[] } = {
    '--fg-black-white': [black.normal, white.normal],
    '--fg-cart-payment': ['#55656B', '#A0A7AA'],
    '--fg-cart-braintree': ['#000', '#A0A7AA'],
    '--fg-cart-description': [dark.lighter, gray.gray3],
    '--fg-white-black': [white.normal, black.normal],
    '--fg-gray1-white': [gray.gray1, white.normal],
    '--fg-gray3-white': [gray.gray3, white.normal],
    '--fg-green-greenLight': [green.dark, green.light],
    '--fg-gray2-gray3': [gray.gray2, gray.gray3],
    '--fg-gray3-dark1': [gray.gray3, dark.dark1],
    '--fg-gray2-white': [gray.gray2, white.normal],
    '--fg-gray-dark': [gray.dark, white.normal],
    '--fg-gray-light': [gray.normal, gray.lighter],
    '--fg-white-dark': [gray.white, dark.darker],
    '--fg-white-lighter': [gray.white, dark.lighter],
    '--fg-state-info': [gray.white, dark.darker],
    '--fg-white-blueDark': [gray.white, blue.dark],
    '--fg-error-text': [state.error, error.darkText],
    '--fg-blue-alert': [green.normal, white.normal],
    '--fg-blue-circle': [white.normal, green.normal],
    '--bg-white-blue': [white.normal, blue.normal],
    '--bg-blue-dark3': [blue.normal, dark.dark3],
    '--bg-white-dark3': [white.normal, dark.dark3],
    '--bg-ash-dark3': [ash.normal, dark.dark3],
    '--bg-ash-dark2': [ash.normal, dark.dark2],
    '--bg-blueDark-blue': [blue.dark, blue.normal],
    '--bg-green-dark3': [green.normal, dark.dark3],
    '--bg-ash-darker': [ash.normal, dark.darker],
    '--bg-ash-normal': [ash.normal, blue.normal],
    '--bg-gray4-dark2': [gray.gray4, dark.dark2],
    '--bg-gray1-green': [gray.gray1, green.normal],
    '--bg-ash-blueDark': [ash.normal, blue.dark],
    '--bg-ashDarker-blueDark': [ash.darker, blue.dark],
    '--bg-white-blueDark': [white.normal, blue.dark],
    '--bg-black-greenNormal': [black.normal, green.normal],
    '--bg-lightBg-darkBg': [error.lightBg, error.darkBg],
    '--bg-lightBg-darkBg2': [error.lightBg2, error.darkBg2],
    '--bg-sand-dark3': [sand.normal, dark.dark3],
    '--bg-errLight-errDark': [error.lightBg, error.darkBg],
    '--bg-gray4-dark1': [gray.gray4, dark.dark1],
    '--bg-light-dark': [sidemenu.light, sidemenu.dark],
    '--bg-hoverlight-blueNormal': [sidemenu.hoverLight, blue.normal],
    '--bg-greenWhite-greenBlack': [green.white, green.black],
    '--bg-sand-dark1': [sand.normal, dark.dark1],
    '--bg-lightSand-darkestSand': [sand.lightSand, sand.darkestSand],
    '--bg-gray3_green': [gray.gray3, green.normal],
    '--bg-lightBlue-blue': [blue.lightBlue, blue.normal],
    '--bg-paypal-text': [paypal.firstLetterLight, paypal.firstLetterDark],
    '--bg-paypal-text2': [paypal.secondLetterLight, paypal.secondLetterDark],
    '--bg-paypal-background': [paypal.bgLight, paypal.bgDark],
    '--bg-paypal-color': [paypal.colorBetweenLight, paypal.colorBetweenDark],
    '--svg-gray1-gray3': [gray.gray1, gray.gray3],
    '--svg-ash-dark2': [ash.normal, dark.dark2],
    '--svg-gray1-white': [gray.gray1, white.normal],
    '--svg-gray2-gray3': [gray.gray2, gray.gray3],
    '--svg-dark4-white': [dark.dark4, white.normal],
    '--svg-green-dark2': [green.normal, dark.dark2],
    '--svg-white-green': [white.normal, green.normal],
    '--svg-white-dark2': [white.normal, dark.dark2],
    '--shadow-normal': ['rgba(189, 189, 189, 0.23)', 'rgba(16, 31, 46, 0.53)'],
    '--shadow-card-default': [
        '0px 4px 23px 0px rgba(189, 189, 189, 0.23)',
        '0px 4px 23px 0px rgba(16, 31, 46, 0.53)',
    ],
    '--shadow-container': [
        '0px 4px 23px rgba(17, 15, 15, 0.15)',
        '0px 3.89px 23.36px rgba(25, 33, 39, 0.53)',
    ],
    '--shadow-modal': [
        '0px 11px 43px rgba(17, 15, 15, 0.53)',
        '0px 11px 43px rgba(25, 33, 39, 0.83)',
    ],
    '--card-hover': [
        '0px 4px 24px rgba(131, 212, 198, 0.23)',
        '0px 11px 43px rgba(25, 33, 39, 0.63)',
    ],
    '--blur-gradient': [
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, .0) 100%)',
        'linear-gradient(0deg, #222F3C 0%, rgba(34, 47, 60, 0) 100%)',
    ],
    '--button-disabled': [
        'linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)',
        'linear-gradient(270deg, #344755 0%, #597A7C 108.89%)',
    ],
    '--disclaimer-red': [red.disclaimer, red.disclaimer],
    '--recent-destination-img-mob': [
        `url(${widget_recent_search_mob.src})`,
        `url(${widget_recent_search_mob_dark.src})`,
    ],
    '--recent-destination-img-ipad': [
        `url(${widget_recent_search_ipad.src})`,
        `url(${widget_recent_search_ipad_dark.src})`,
    ],
    '--recent-destination-img-desk': [
        `url(${widget_recent_search_desk.src})`,
        `url(${widget_recent_search_desk_dark.src})`,
    ],
    '--emptyattraction_mobile': [
        `url(${emptyattraction_mobile.src})`,
        `url(${emptyattraction_mobile_dark.src}`,
    ],
    '--emptyattraction_ipad': [
        `url(${emptyattraction_ipad.src})`,
        `url(${emptyattraction_ipad_dark.src}`,
    ],
    '--emptyattraction_desktop': [
        `url(${emptyattraction_desktop.src})`,
        `url(${emptyattraction_desktop_dark.src}`,
    ],
    '--main-background': [`url(${HomePageLight.src})`, `url(${HomePageDark.src})`],
    '--transparent-white-blue': ['rgba(255, 255, 255, 0)', 'rgba(34, 47, 60, 0)'],
    '--gradient-gray-blueDark': [Palette.gradient.gray, blue.dark],
    '--bg-promo-subtitle': ['rgba(224, 250, 254, 0.5)', 'rgba(224, 250, 254, 0.1)'],
};

export const cssVars = {
    fg_cart_description: 'var(--fg-cart-description)',
    bg_promo_subtitle: 'var(--bg-promo-subtitle)',
    fg_black_white: `var(--fg-black-white)`,
    fg_white_black: `var(--fg-white-black)`,
    fg_gray1_white: `var(--fg-gray1-white)`,
    fg_gray3_white: `var(--fg-gray3-white)`,
    fg_green_greenLight: `var(--fg-green-greenLight)`,
    fg_gray2_gray3: `var(--fg-gray2-gray3)`,
    fg_gray3_dark1: `var(--fg-gray3-dark1)`,
    fg_gray2_white: `var(--fg-gray2-white)`,
    fg_gray_dark: `var(--fg-gray-dark)`,
    fg_white_dark: `var(--fg-white-dark)`,
    fg_white_lighter: `var(--fg-white-lighter)`,
    fg_white_blueDark: `var(--fg-white-blueDark)`,
    fg_error_text: `var(--fg-error-text)`,
    fg_gray_light: 'var(--fg-gray-light)',
    fg_blue_alert: `var(--fg-blue-alert)`,
    bg_blue_circle: `var(--fg-blue-circle)`,
    bg_white_blue: `var(--bg-white-blue)`,
    bg_blue_dark3: `var(--bg-blue-dark3)`,
    bg_white_dark3: `var(--bg-white-dark3)`,
    bg_ash_dark3: `var(--bg-ash-dark3)`,
    bg_ash_dark2: `var(--bg-ash-dark2)`,
    bg_blueDark_blue: `var(--bg-blueDark-blue)`,
    bg_green_dark3: `var(--bg-green-dark3)`,
    bg_ash_darker: `var(--bg-ash-darker)`,
    bg_ash_normal: `var(--bg-ash-normal)`,
    bg_gray4_dark2: `var(--bg-gray4-dark2)`,
    bg_gray1_green: `var(--bg-gray1-green)`,
    bg_ash_blueDark: `var(--bg-ash-blueDark)`,
    bg_ashDarker_blueDark: `var(--bg-ashDarker-blueDark)`,
    bg_white_blueDark: `var(--bg-white-blueDark)`,
    bg_black_greenNormal: `var(--bg-black-greenNormal)`,
    bg_lightBg_darkBg: `var(--bg-lightBg-darkBg)`,
    bg_lightBg_darkBg2: `var(--bg-lightBg-darkBg2)`,
    bg_sand_dark3: `var(--bg-sand-dark3)`,
    bg_errLight_errDark: `var(--bg-errLight-errDark)`,
    bg_gray4_dark1: `var(--bg-gray4-dark1)`,
    bg_light_dark: `var(--bg-light-dark)`,
    bg_hoverlight_blueNormal: `var(--bg-hoverlight-blueNormal)`,
    bg_greenWhite_greenBlack: `var(--bg-greenWhite-greenBlack)`,
    bg_gray3_green: `var(--bg-gray3_green)`,
    bg_sand_dark1: `var(--bg-sand-dark1)`,
    bg_lightSand_darkestSand: `var(--bg-lightSand-darkestSand)`,
    bg_lightBlue_blue: `var(--bg-lightBlue-blue)`,
    bg_paypal_text: `var(--bg-paypal-text)`,
    bg_paypal_text2: `var(--bg-paypal-text2)`,
    bg_paypal_background: `var(--bg-paypal-background)`,
    bg_paypal_color: `var(--bg-paypal-color)`,
    svg_gray1_gray3: `var(--svg-gray1-gray3)`,
    svg_ash_dark2: `var(--svg-ash-dark2)`,
    svg_gray1_white: `var(--svg-gray1-white)`,
    svg_gray2_gray3: `var(--svg-gray2-gray3)`,
    svg_dark1_white: `var(--svg-dark4-white)`,
    svg_green_dark2: `var(--svg-green-dark2)`,
    svg_white_green: `var(--svg-white-green)`,
    svg_white_dark2: `var(--svg-white-dark2)`,
    shadow_normal: `var(--shadow-normal)`,
    shadow_card_default: `var(--shadow-card-default)`,
    shadow_container: `var(--shadow-container)`,
    shadow_modal: `var(--shadow-modal)`,
    shadow_card_hover: `var(--card-hover)`,
    blur_gradient: `var(--blur-gradient)`,
    button_disabled: `var(--button-disabled)`,
    disclaimer_red: `var(--disclaimer-red)`,
    recent_destination_img_mob: `var(--recent-destination-img-mob)`,
    recent_destination_img_ipad: `var(--recent-destination-img-ipad)`,
    recent_destination_img_desk: `var(--recent-destination-img-desk)`,
    empty_attraction_mob: `var(--emptyattraction_mobile)`,
    empty_attraction_ipad: `var(--emptyattraction_ipad)`,
    empty_attraction_desktop: `var(--emptyattraction_desktop)`,
    main_background: `var(--main-background)`,
    transparent_white_blue: `var(--transparent-white-blue)`,
    gradient_gray_blueDark: `var(--gradient-gray-blueDark)`,
};
